import service from '.'

/**
 * ## ログインAPI
 *
 * | STATUS | Result |
 * | ------- | ------- |
 * | 200  | 成功 |
 * | 400 | パラメーター/リクエストボディ不正  |
 * | 401 | 認証エラー/アカウントロック？  |
 * | 415 | Content-Type不正  |
 *
 */

export const LoginApi = (path: string, { arg }: { arg: LoginApiRequest }): Promise<BaseResponse<LoginApiResult>> =>
  service.post(path, arg)

/**
 * ## ログアウトAPI
 *
 * | STATUS | Result |
 * | ------- | ------- |
 * | 200  | 成功 |
 * | 401 | 認証エラー  |
 */

export const LogoutApi = (id: string): Promise<BaseResponse<{ id: string }>> => service.delete('/logout/' + id)

/**
 * ## 利用者アカウント登録API
 *
 * アカウント名を登録しているユーザーに対してパスワードを設定しアカウントの登録を完了します
 *
 * | STATUS | Result |
 * | ------- | ------- |
 * | 204  | 成功（中身無し） |
 * | 400  | パラメーター/リクエストボディ不正 |
 * | 401 | 認証エラー  |
 * | 403 | 権限エラー  |
 * | 409 | すでにユーザーが存在する  |
 * | 415 | Content-Type不正  |
 */

export const CreateUserAccountApi = (data: CreateUserAccountApiRequest): Promise<BaseResponse<void>> =>
  service.post('/user_account', data)

/**
 * ## 利用者アカウント取得API
 *
 * | STATUS | Result |
 * | ------- | ------- |
 * | 200  | 成功 |
 * | 404  | リソースが見つかりません  |
 */

export const GetUserAccountApi = (): Promise<BaseResponse<GetUserAccountApiResult>> => service.get('/user_account/')

/**
 * ## 利用者アカウント更新API
 *
 * パスワードやメール受信設定変更の変更を行います
 *
 * | STATUS | Result |
 * | ------- | ------- |
 * | 200  | 成功 |
 * | 415  | パッチ ドキュメントの形式がサポートされていません。  |
 * | 400 | 無効な形式のパッチ ドキュメントです。  |
 * | 409 | パッチ ドキュメントは有効ですが、現在の状態のリソースに変更を適用することはできません。  |
 */
export const UpdateUserAccountApi = (
  data: UpdateUserAccountApiRequest
): Promise<BaseResponse<UpdateUserAccountApiResult>> => service.patch('/user_account', data)

/**
 * ## 利用者登録施設取得API
 *
 *
 *
 */

/**
 * ## 同意API
 *
 * 利用規約やプライバシーポリシーに同意するAPI
 * 他に同意が必要なければtokenを返却する（サーバー側ではログインAPI再呼び出し）
 *
 */

export const AgreeApi = (path: string, { arg }: { arg: AgreeApiRequest }): Promise<BaseResponse<AgreeApiResult>> =>
  service.post(path, arg)

/**
 * ## 初期登録用API
 *
 */

export const GetUserForRegisterApi = (userCode: string, token: string): Promise<BaseResponse<UserAccountModel>> =>

  service.get('/user_account_init', { params: {userCode, token} })

export const RegisterUserApi = (userCode: string, token: string, password: string): Promise<BaseResponse<UserAccountModel>> =>
  service.post('/user_account_register', {userCode, token, password})

/**
 * ## パスワード再設定用API
 *
 */

export const GetUserForResetApi = (userCode: string, token: string): Promise<BaseResponse<UserAccountModel>> =>

  service.get('/user_account_reset', { params: {userCode, token} })

export const ResetUserApi = (userCode: string, token: string, password: string): Promise<BaseResponse<UserAccountModel>> =>
  service.post('/user_account_reset', {userCode, token, password})

