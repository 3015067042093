import { createContext, useState } from "react";

export const UserAccountIdContext = createContext({} as {
    userAccountId: string
    setUserAccountId: React.Dispatch<React.SetStateAction<string>>
})

export const UserAccountIdProvider = ({ children }: {
    children?: React.ReactNode;
}) => {

    // TODO useAccountIdの設定
    const [userAccountId, setUserAccountId] = useState("1")
    const userAccountIdValue = { userAccountId, setUserAccountId }

    return (
        <UserAccountIdContext.Provider value={userAccountIdValue}>
            {children}
        </UserAccountIdContext.Provider>
    );
};