import { Button, Col, Form, Input, Layout, Modal, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useUserAccount } from '@/hooks/useUserAccount'
import { useOtherInformation } from '@/hooks/useOtherInformation'
import { useForm } from 'antd/es/form/Form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

const { Title } = Typography

type FieldType = {
  account?: string
  password?: string
}

const View = () => {
  const { login, agree } = useUserAccount()
  const { data, trigger, isMutating } = login()
  const { data: agreeData, trigger: agreeTrigger, isMutating: agreeIsMutating } = agree()
  const { getOtherInformation } = useOtherInformation()
  const [infoCode, setInfoCode] = useState(null)
  const { otherInformationData, isLoading, isValidating } = getOtherInformation(infoCode)
  const [open, setOpen] = useState<boolean>(false)
  const [form] = useForm()
  const [reCaptchaToken, setReCaptchaToken] = useState('')
  const navigate = useNavigate()

  // reCAPTCHA設定
  const handleRecaptcha = (value: any) => {
    setReCaptchaToken(value)
  }

  const onFinish = (values: FieldType) => {
    if (values.account && values.password) {
      trigger({
        account: values.account,
        password: values.password,
        reCaptchaToken: reCaptchaToken,
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  const agreeTerms = () => {
    agreeTrigger({
      infoCode: infoCode,
      version: otherInformationData?.version,
      account: form.getFieldValue('account'),
      password: form.getFieldValue('password'),
      reCaptchaToken: reCaptchaToken,
      agree: true,
    })
    setOpen(false)
  }

  useEffect(() => {
    if (data?.code === 0) {
      if (data.data.token) {
        navigate('/')
        localStorage.setItem('react_management_token', data.data.token)
      } else if (data.data.infoCode) {
        if (otherInformationData?.content) {
          setOpen(true)
        } else {
          setInfoCode(data.data.infoCode)
          setOpen(true)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (agreeData?.code === 0) {
      if (agreeData.data.token) {
        navigate('/')
        localStorage.setItem('react_management_token', agreeData.data.token)
      } else if (agreeData.data.infoCode) {
        setInfoCode(String(agreeData.data.infoCode))
      }
    }
  }, [agreeData])

  useEffect(() => {
    if (otherInformationData?.content) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isLoading, isValidating])

  return (
    <Layout>
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={{ span: 20 }} md={{ span: 10 }} xxl={{ span: 8 }}>
          <Title level={3} style={{ textAlign: 'center', marginBottom: 64 }}>
            オアシスルーム予約システム
          </Title>
          <Form name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form}>
            <Form.Item<FieldType>
              name="account"
              rules={[{ required: true, message: 'アカウント名を入力してください' }]}
            >
              <Input placeholder="アカウント名" />
            </Form.Item>

            <Form.Item<FieldType> name="password" rules={[{ required: true, message: 'パスワードを入力してください' }]}>
              <Input.Password placeholder="パスワード" />
            </Form.Item>

            {import.meta.env.VITE_USE_RECAPTCHA == '1' && (
              <Form.Item>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <ReCAPTCHA sitekey={import.meta.env.VITE_OASIS_SITE_KEY} onChange={handleRecaptcha} badge="inline" />
                </div>
              </Form.Item>
            )}

            <Form.Item hidden={import.meta.env.VITE_USE_RECAPTCHA == '1' && !reCaptchaToken}>
              <Button type="primary" htmlType="submit" block>
                ログイン
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {otherInformationData && (
        <Modal
          title={otherInformationData.infoCode === 1 ? '利用規約' : 'プライバシーポリシー'}
          okText="同意する"
          cancelText="同意しない"
          open={open}
          onCancel={() => setOpen(false)}
          onOk={() => agreeTerms()}
          forceRender={true}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}
        >
          <ReactQuill value={otherInformationData.content} readOnly={true} theme={'bubble'} />
        </Modal>
      )}
    </Layout>
  )
}

export default View
