import { AgreeApi, GetUserAccountApi, LoginApi, UpdateUserAccountApi, GetUserForRegisterApi, RegisterUserApi, GetUserForResetApi, ResetUserApi } from '@/api/userAccount'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'

export const useUserAccount = () => {
  const options = {
    // ウィンドウがフォーカスされたときに自動的に再検証する
    revalidateOnFocus: false,
    // ブラウザが接続を回復すると自動的に再検証する
    revalidateOnReconnect: false,
    // fetcherでエラーが起きた時に再検証する
    shouldRetryOnError: false,
  }

  const getUserAccount = useCallback(async () => {
    try {
      const { data } = await GetUserAccountApi()
      return data
    } catch (err) {
      if (err instanceof AxiosError) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403 || err.response.status === 500)
        ) {
          console.log(err.response.status)
        } else {
          console.log(err)
        }
      }
      console.log(err)
    }
  }, [])

  const updateUserAccount = useCallback(async (request: UpdateUserAccountApiRequest) => {
    try {
      const { data } = await UpdateUserAccountApi(request)
      message.success({
        content: 'リマインドメール受信タイミングが更新されました',
      })
      return data
    } catch (err) {
      if (err instanceof AxiosError) {
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403 || err.response.status === 500)
        ) {
          console.log(err.response.status)
        } else {
          console.log(err)
        }
      }
      console.log(err)
    }
  }, [])

  const login = () => {
    return useSWRMutation('/login', LoginApi)
  }

  const agree = () => {
    return useSWRMutation('/agree', AgreeApi)
  }

  const getUserForRegister = async (userCode: string, token: string) => {
    const { data } = await GetUserForRegisterApi(userCode, token)
    return data
  }

  const registerUser = async (userCode: string, token: string, password: string) => {
    const { data } = await RegisterUserApi(userCode, token, password)
    return data
  }

  const getUserForReset = async (userCode: string, token: string) => {
    const { data } = await GetUserForResetApi(userCode, token)
    return data
  }

  const resetUser = async (userCode: string, token: string, password: string) => {
    const { data } = await ResetUserApi(userCode, token, password)
    return data
  }

  return { getUserAccount, updateUserAccount, login, agree, getUserForRegister, registerUser, getUserForReset, resetUser }
}
