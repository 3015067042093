import service from ".";

/**
 * ## 日付取得API
 * 
 * 今日の日付を取得します
 * 
 * | STATUS | Result |
 * | ------- | ------- |
 * | 200  | 成功 |
 * | 404  | リソースが見つかりません  |
 */

export const GetReferenceDateApi = (path:string): Promise<BaseResponse<Date>> =>
    service.get(path);
