import { message } from 'antd'
import axios, { AxiosResponse } from 'axios'

// TODO patchの時のリクエストheader設定の切り分け

// create axios service
const service = axios.create({
  baseURL: '/api', // TODO: change to your own url
  timeout: 20000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  req => {
    const token = localStorage.getItem('react_management_token')
    req.headers['Authorization'] = `Bearer ${token}`
    return req
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res: AxiosResponse<BaseResponse<any>> = response
    const { data } = res.data

    if (data.code === 0) {
      return data
    }

    /**
     * messageを表示するタイプのエラー
     */
    const ERROR_MESSAGE = [40000]
    /**
     * modalを表示するタイプのエラー
     */
    const ERROR_MODAL = [40001]
    /**
     * 強制ログアウトするタイプのエラー
     * これはここに書けなさそう
     */
    const INVALID_TOKEN = [40100]

    /**
     * サービス停止中にリダイレクする
     * これもここに書けなさそう
     */
    const MAINTENANCE = [50300]

    if (MAINTENANCE.includes(data.code)) {
      window.location.href = '/maintenance'
    }

    if (INVALID_TOKEN.includes(data.code)) {
      // TODO ログイン画面へ遷移
      return Promise.reject(new Error(data))
    }

    if (ERROR_MESSAGE.includes(data.code)) {
      message.error({
        content: data.msg,
      })
      //return data
      return Promise.reject(new Error(data.msg))
    }

    // if (ERROR_MODAL.includes(data.code)) {
    //     // TODO modal表示
    //     return Promise.reject(new Error(data))
    // }

    return Promise.reject(new Error(data))
  },
  error => {
    console.log('Error: ' + error.message)

    if (error.response?.data) {
      const { data } = error.response?.data

      const INVALID_TOKEN = [40100]

      if (INVALID_TOKEN.includes(data.code)) {
        const token = localStorage.removeItem('react_management_token')
        document.location.href = '/login'
        return Promise.reject(new Error(data))
      }
    }
    return Promise.reject(error)
  }
)

export default service
