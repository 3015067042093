import React, { useContext } from 'react'
import { Breadcrumb, Button, Drawer, Grid, Layout, theme } from 'antd'
import { Link, Outlet, useLocation } from 'react-router-dom'
import MainMenu from '@/components/MainMenu'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import MyMenu from '@/components/MyMenu'
import { breadcrumbNameMap, pathNameMap } from '@/enums/route'
import { Collapsed } from '@/providers/Collapsed'
import { ReferenceDateContext } from '@/providers/ReferenceDate'

const { Header, Content, Sider } = Layout

const View: React.FC = () => {
  const { collapsed, setCollapsed } = useContext(Collapsed)
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const location = useLocation()
  const { referenceDate } = useContext(ReferenceDateContext)
  const pathSnippets = location.pathname.split('/').filter(i => i)

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    if (!breadcrumbNameMap[url]) {
      return {
        title: <>詳細</>,
      }
    }

    if (pathSnippets.length - 1 === index || url === pathNameMap.news || url === pathNameMap.reservation) {
      return {
        title: <>{breadcrumbNameMap[url]}</>,
      }
    }

    return {
      title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    }
  })

  const breadcrumbItems =
    location.pathname === pathNameMap.home
      ? [
          {
            title: <>ホーム</>,
          },
        ]
      : [
          {
            title: <Link to={pathNameMap.home}>ホーム</Link>,
          },
        ].concat(extraBreadcrumbItems)

  const {
    token: { colorBgContainer },
  } = theme.useToken()

  if (!referenceDate) {
    return null  // <Loading />
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {screens.md ? (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth="0" // 0以外の値に設定するとたたんでもアイコンとかが表示される
          style={{
            background: 'white',
            height: '100vh',
            position: 'sticky',
            top: 0,
          }}
          width="230px"
        >
          <h1
            style={{
              margin: 25,
              background: 'rgba(255, 255, 255, 0.2)',
              textAlign: 'center',
            }}
          >
            <Link to="/home">オアシスルーム予約</Link>
          </h1>
          <MainMenu />
        </Sider>
      ) : (
        <Drawer
          open={collapsed}
          placement="left"
          onClose={() => setCollapsed(false)}
          width="230px"
          bodyStyle={{ padding: 0 }}
          title={
            <h1
              style={{
                textAlign: 'center',
              }}
            >
              <Link to="/home" onClick={() => setCollapsed(false)}>
                オアシスルーム予約
              </Link>
            </h1>
          }
          closable={false}
        >
          <MainMenu />
        </Drawer>
      )}
      <Layout className="site-layout">
        <Header
          style={{
            position: 'sticky',
            top: 0,
            background: colorBgContainer,
            display: 'flex',
            justifyContent: 'space-between',
            padding: 0,
            zIndex: 1000,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <MyMenu />
        </Header>
        <Content
          style={{
            margin: '8px 8px 0',
            background: colorBgContainer,
            padding: '16px',
            overflow: 'auto',
          }}
        >
          <Breadcrumb items={breadcrumbItems} style={{ marginBottom: '16px' }} />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default View
