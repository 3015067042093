import React from 'react'
import ReactDOM from 'react-dom/client'
// remove all default browser styles
import 'reset-css'

// global styles
import '@/assets/styles/global.scss'

// state management
import { Provider } from 'react-redux'

// mockjs
// if (import.meta.env.MODE === 'development' && import.meta.env.DEV) {
//   import('@/mock')
// }

import App from './App'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import { pipeComponents } from './utils/pipeComponents'
import { ReadOnlyProvider } from './providers/ReadOnly'
import { CollapsedProvider } from './providers/Collapsed'
import { GetFacilityNewsParamsProvider } from './providers/GetFacilityNewsParams'
import { GetCityNewsParamsProvider } from './providers/GetCityNewsParams'
import { UserAccountIdProvider } from './providers/UserAccountId'
//import { ReferenceDateProvider } from './providers/ReferenceDate'
import { UnavailableTimeProvider } from './providers/UnavailableTime'
import { BaseProvider } from './providers/Base'

// pipeComponentsがエラーなくなるのはわかるけどReadOnlyProviderのエラーがなくなる理由がよくわからない
const ContextProvider = pipeComponents(
  ReadOnlyProvider,
  CollapsedProvider,
  GetFacilityNewsParamsProvider,
  GetCityNewsParamsProvider,
  UserAccountIdProvider,
  // ReferenceDateProvider,
  UnavailableTimeProvider,
  BaseProvider
)

const rootElement = document.getElementById('root')

if (rootElement !== null && rootElement.childNodes.length === 0) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        {/*@ts-ignore*/}
        <ContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ContextProvider>
      </Provider>
    </React.StrictMode>
  )
}

// ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <ContextProvider>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </ContextProvider>
//     </Provider>
//   </React.StrictMode>
// );
