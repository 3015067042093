import { useDate } from "@/hooks/useDate";
import { createContext } from "react";

export const ReferenceDateContext = createContext({} as {
    referenceDate: Date
})

export const ReferenceDateProvider = ({ children }: {
    children?: React.ReactNode;
}) => {
    // そもそもこれだとログインする前に呼ばれるからダメそう。あくまで定義だけする方がいいかも
    const { getReferenceDate } = useDate();
    
    const { date: referenceDate, maintenance} = getReferenceDate();

    if (maintenance) {
      window.location.href = "/maintenance";
    }

    const userReferenceDateValue = { referenceDate };
    
    return (
        <ReferenceDateContext.Provider value={userReferenceDateValue}>
            {children}
        </ReferenceDateContext.Provider>
    );
};
