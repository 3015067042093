import { Spin } from "antd"


const Comp = () => {

    return (
        <Spin
            tip="ローディング中"
            size="large"
            style={{
                margin: "150px 0",
            }}
        >
            <div />
        </Spin>
    )
}

export default Comp