import React from 'react';
import {
    LogoutOutlined,
    MailOutlined,
    QuestionCircleOutlined,
    TagOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { BsShieldLock } from "react-icons/bs";
import { SiGnuprivacyguard } from "react-icons/si";
import { pathNameMap } from '@/enums/route';

const Comp: React.FC = () => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem("react_management_token")
        navigate(pathNameMap.login)
    }
    const items: MenuProps['items'] = [
        {
            label: (
                <Link to={pathNameMap.changePassword}>パスワード変更</Link>
            ),
            key: pathNameMap.changePassword,
            icon: <BsShieldLock />,
        },
        {
            label: (
                <Link to={pathNameMap.changeMailSetting}>メール受信設定変更</Link>
            ),
            key: pathNameMap.changeMailSetting,
            icon: <MailOutlined />,
        },
        {
            label: (
                <Link to={pathNameMap.nickNameSetting}>ニックネーム設定</Link>
            ),
            key: pathNameMap.nickNameSetting,
            icon: <TagOutlined />,
        },
        {
            label: (
                <Link to={pathNameMap.termsOfUse}>利用規約</Link>
            ),
            key: pathNameMap.termsOfUse,
            icon: <TeamOutlined />,
        },
        {
            label: (
                <Link to={pathNameMap.privacyPolicy}>プライバシーポリシー</Link>
            ),
            key: pathNameMap.privacyPolicy,
            icon: <SiGnuprivacyguard />,
        },
        {
            label: (
                <Link to={pathNameMap.help}>ヘルプ</Link>
            ),
            key: pathNameMap.help,
            icon: <QuestionCircleOutlined />,
        },
        {
            label: "ログアウト",
            key: pathNameMap.logout,
            icon: <LogoutOutlined />,
            onClick: () => logout()
        },
    ];
    return (
        <Dropdown
            menu={{ items }}
            placement="bottom"

        >
            <UserOutlined
                style={{
                    fontSize: '20px',
                    width: 38,
                }}
            />
        </Dropdown>
    )
}

export default Comp