import handleArr from './index';
import handleReserve from "@/store/ReserveState";

const defaultState = {
    ...handleArr.state,
}

let reducer = (state = defaultState, action: {type: string}) => {
    let newState = JSON.parse(JSON.stringify(state));
    let actionNames:{[key: string]: any} = handleReserve.actionNames
    let actions: {[key:string]: any} = handleReserve.actions

    // get the action name from the action type
    for (let key in actionNames) {
        if (actionNames[key] === action.type) {
            actions[actionNames[key]](newState, action);
            break;
        }
    }

    return newState;
}

export default reducer;