import React, { useContext, useState } from 'react';
import {
    BellOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import { Grid, Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItem from 'antd/es/menu/MenuItem';
import { Collapsed } from '@/providers/Collapsed';
import { BsBuilding, BsCalendarPlus, BsShopWindow } from "react-icons/bs";
import { TbCalendarTime } from "react-icons/tb";
import { pathNameMap } from '@/enums/route';

type MenuItem = {
    key: string,
    label: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
};

const items: MenuItem[] = [
    {
        label: 'お知らせ',
        key: pathNameMap.news,
        icon: <BellOutlined />,
        children: [
            {
                label: "区からのお知らせ",
                key: pathNameMap.cityNews,
                icon: <BsBuilding />
            },
            {
                label: "施設からのお知らせ",
                key: pathNameMap.facilityNews,
                icon: <BsShopWindow />
            },
        ]
    },
    {
        label: '予約',
        key: pathNameMap.reservation,
        icon: <CalendarOutlined />,
        children: [
            {
                label: "新規予約",
                key: pathNameMap.newReservation,
                icon: <BsCalendarPlus />
            },
            {
                label: "予約一覧",
                key: pathNameMap.listReservation,
                icon: <TbCalendarTime />
            },
        ]
    },
];

const Comp: React.FC = () => {
    const navigateTo = useNavigate();
    const currentRoute = useLocation();
    const { setCollapsed } = useContext(Collapsed);
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    const menuClick = (e: { key: string }) => {
        navigateTo(e.key);
        // スマホの時だけ、menuを押下したらDrawerを閉じる
        if (!screens.md) {
            setCollapsed(false)
        }
    };

    // set the openKey initial value
    let initialOpenKey: string = '';

    const findKey = (obj: { key: string }): boolean => {
        return obj.key === currentRoute.pathname;
    }

    initialOpenKey = items.filter((item: MenuItem) => item!.children ? item!.children.find(findKey) : false)
        .map((item: MenuItem) => item!.key)[0] as string
        || '';

    const [openKeys, setOpenKeys] = useState([initialOpenKey]);
    // handleOpenKeys is executed when the menu is opened or closed
    const handleOpenKeys = (keys: string[]) => {
        // keys is an array of open keys which record the path of the open menu
        // set the last key of the array to openKeys
        // only the last menu is open
        setOpenKeys([keys.pop() as string]);
    }

    return (
        <Menu theme="light"
            // default selected key is the current route
            defaultSelectedKeys={[currentRoute.pathname]}
            mode="inline"
            items={items}
            onClick={menuClick}
            onOpenChange={handleOpenKeys}
            openKeys={openKeys} />
    )
}

export default Comp