import { GetReferenceDateApi } from '@/api/date'
import useSWR from 'swr'

export const useDate = () => {
  const options = {
    // ウィンドウがフォーカスされたときに自動的に再検証する
    revalidateOnFocus: false,
    // ブラウザが接続を回復すると自動的に再検証する
    revalidateOnReconnect: false,
    // fetcherでエラーが起きた時に再検証する
    shouldRetryOnError: true,
  }

  const getReferenceDate = () => {
    const { data: res, error, isLoading, isValidating } = useSWR('/reference-date', GetReferenceDateApi, options)
    return {
      date: res ? res.data.date : null,
      maintenance: res ? res.data.maintenance || false : null,
    }
  }

  return { getReferenceDate }
}
