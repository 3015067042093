export const pathNameMap: Record<string, string> = {
    home: "/home",
    news: "/news",
    cityNews: "/news/city",
    facilityNews: "/news/facility",
    reservation: "/reservation",
    newReservation: "/reservation/new",
    newCalendarReservation: "/reservation/new/calendar",
    listReservation: "/reservation/list",
    listCalendarReservation: "/reservation/list/calendar",
    changePassword: "/change-password",
    changeMailSetting: "/change-mail-setting",
    nickNameSetting: "/nickname-setting",
    termsOfUse: "/terms-of-use",
    privacyPolicy: "/privacy-policy",
    help: "/help",
    login: "/login",
    maintenance: "/maintenance",
    register: "/f/register",
    successRegister: "/f/register/success",
    //completeChangePassword: "/f/change-password",
    resetPassword: "/f/reset-password",
    successResetPassword: "/f/reset-password/success",
    tokenError: "/f/tokenerror",
    logout: "/logout"
}

export const breadcrumbNameMap: Record<string, string> = {
    [pathNameMap.news]: "お知らせ",
    [pathNameMap.cityNews]: "区からのお知らせ",
    [pathNameMap.facilityNews]: "施設からのお知らせ",
    [pathNameMap.reservation]: "予約",
    [pathNameMap.newReservation]: "新規予約",
    [pathNameMap.newCalendarReservation]: "カレンダー",
    [pathNameMap.listReservation]: "予約一覧",
    [pathNameMap.listCalendarReservation]: "カレンダー",
    [pathNameMap.changePassword]: "パスワード変更",
    [pathNameMap.changeMailSetting]: "メール受信設定変更",
    [pathNameMap.nickNameSetting]: "ニックネーム設定",
    [pathNameMap.termsOfUse]: "利用規約",
    [pathNameMap.privacyPolicy]: "プライバシーポリシー",
    [pathNameMap.help]: "ヘルプ",
}
