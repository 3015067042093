import React from 'react'

type Component = React.ComponentType // children のみを prop として持つコンポーネント
type AtLeast2 = [Component, Component, ...Component[]] // 型で引数の数を２つ以上に限定

// コンポーネント用部分適用関数
export const partial = <P extends object>(
  Component: React.ComponentType<P>,
  props: P
): Component => ({ children }: {
  children?: React.ReactNode;
}) => React.createElement(Component, props, [children])

// コンポーネント用合成関数
export const pipeComponents = (...components: AtLeast2): Component => {
  return components.reduce((Acc: any, Cur: any) => {
    return ({ children }: {
      children?: React.ReactNode;
    }) => (
      <Acc>
        <Cur>{children}</Cur>
      </Acc>
    )
  })
}
