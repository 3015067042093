import Layout from '../views/Layout'
import Login from '../views/Login'
import { Navigate } from 'react-router-dom'
import React, { lazy } from 'react'
import WithFooter from '@/views/WithFooter'
import Loading from '@/components/Loading'
import { pathNameMap } from '@/enums/route'

const ReservationNew = lazy(() => import('@/views/Reservation/New'))
const ReservationCalendar = lazy(() => import('@/views/Reservation/Calendar'))
const ReservationList = lazy(() => import('@/views/Reservation/List'))
const ChangePassword = lazy(() => import('@/views/ChangePassword'))
const Home = lazy(() => import('@/views/Home'))
const NewsCity = lazy(() => import('@/views/News/City'))
const NewsFacility = lazy(() => import('@/views/News/Facility'))
const ChangeMailSetting = lazy(() => import('@/views/ChangeMailSetting'))
const NicknameSetting = lazy(() => import('@/views/NicknameSetting'))
const TermsOfUse = lazy(() => import('@/views/TermsOfUse'))
const PrivacyPolicy = lazy(() => import('@/views/PrivacyPolicy'))
const Help = lazy(() => import('@/views/Help'))
const NewsDetail = lazy(() => import('@/views/News/Detail'))
const Maintenance = lazy(() => import('@/views/Maintenance'))
const NotFound = lazy(() => import('@/views/NotFound'))
const Register = lazy(() => import('@/views/Register'))
const SuccessRegister = lazy(() => import('@/views/SuccessRegister'))
const ResetPassword = lazy(() => import('@/views/ResetPassword'))
const SuccessResetPassword = lazy(() => import('@/views/SuccessResetPassword'))
const TokenError = lazy(() => import('@/views/TokenError'))

const withLoadingComponent = (comp: JSX.Element) => {
  return <React.Suspense fallback={<Loading />}>{comp}</React.Suspense>
}

// TODO ログインしているか取得する
const isLogin = true

const routes = [
  {
    path: '/',
    element: <Navigate to={pathNameMap.home} />,
  },
  {
    path: '/',
    element: isLogin ? <Layout /> : <WithFooter />,
    errorElement: <NotFound />,
    children: [
      {
        path: pathNameMap.home,
        element: withLoadingComponent(<Home />),
      },
      {
        path: pathNameMap.cityNews,
        element: withLoadingComponent(<NewsCity />),
      },
      {
        path: pathNameMap.facilityNews,
        element: withLoadingComponent(<NewsFacility />),
      },
      {
        path: `${pathNameMap.cityNews}/:id`,
        element: withLoadingComponent(<NewsDetail />),
      },
      {
        path: `${pathNameMap.facilityNews}/:id`,
        element: withLoadingComponent(<NewsDetail />),
      },
      {
        path: pathNameMap.newReservation,
        element: withLoadingComponent(<ReservationNew />),
      },
      {
        path: pathNameMap.listReservation,
        element: withLoadingComponent(<ReservationList />),
      },
      {
        path: `${pathNameMap.listReservation}/:id`,
        element: withLoadingComponent(<ReservationList />),
      },
      {
        path: pathNameMap.newCalendarReservation,
        element: withLoadingComponent(<ReservationCalendar />),
      },
      {
        path: pathNameMap.listCalendarReservation,
        element: withLoadingComponent(<ReservationCalendar />),
      },
      {
        path: pathNameMap.changePassword,
        element: withLoadingComponent(<ChangePassword />),
      },
      {
        path: pathNameMap.changeMailSetting,
        element: withLoadingComponent(<ChangeMailSetting />),
      },
      {
        path: pathNameMap.nickNameSetting,
        element: withLoadingComponent(<NicknameSetting />),
      },
      {
        path: pathNameMap.termsOfUse,
        element: withLoadingComponent(<TermsOfUse />),
      },
      {
        path: pathNameMap.privacyPolicy,
        element: withLoadingComponent(<PrivacyPolicy />),
      },
      {
        path: pathNameMap.help,
        element: withLoadingComponent(<Help />),
      },
    ],
  },
  {
    path: pathNameMap.login,
    element: <Login />,
  },
  // nginxなどwebサーバー側でここに遷移させる
  {
    path: pathNameMap.maintenance,
    element: withLoadingComponent(<Maintenance />),
  },
  {
    path: '/f',
    element: <WithFooter />,
    children: [
      {
        path: pathNameMap.register,
        element: withLoadingComponent(<Register />),
      },
      {
        path: pathNameMap.successRegister,
        element: withLoadingComponent(<SuccessRegister />),
      },
      {
        path: pathNameMap.resetPassword,
        element: withLoadingComponent(<ResetPassword />),
      },
      {
        path: pathNameMap.successResetPassword,
        element: withLoadingComponent(<SuccessResetPassword />),
      },
      {
        path: pathNameMap.tokenError,
        element: withLoadingComponent(<TokenError />),
      },
    ],
  },
  // 404
  {
    path: '*',
    element: withLoadingComponent(<NotFound />),
  },
]

export default routes
