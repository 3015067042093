import { createContext, useState } from "react";

export const CityNewsParams = createContext({} as {
    cityNewsParams: GetNewsListApiRequest
    setCityNewsParams: React.Dispatch<React.SetStateAction<GetNewsListApiRequest>>
})

export const GetCityNewsParamsProvider = ({ children }: {
    children?: React.ReactNode;
}) => {

    const [cityNewsParams, setCityNewsParams] = useState<GetNewsListApiRequest>({ page: 1, pageSize: 3, newsCode: 2, userAccountId: ""});
    const cityNewsParamsValue = { cityNewsParams, setCityNewsParams }

    return (
        <CityNewsParams.Provider value={cityNewsParamsValue}>
            {children}
        </CityNewsParams.Provider>
    );
};
