import { ConfigProvider, message } from 'antd'
import { useEffect, useContext } from 'react'
import { useRoutes, useLocation, useNavigate } from 'react-router-dom'
import routes from './router'
import jaJP from 'antd/locale/ja_JP';
import { pathNameMap } from './enums/route';
import { ReferenceDateProvider } from './providers/ReferenceDate'

const ToLogin = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(pathNameMap.login)
    // message.warning('Please login first')
  }, [])

  return <div></div>
}

const ToHome = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate('/')
  }, [])
  return <div></div>
}

const BeforeRouterEnter = () => {
  const router = useRoutes(routes)
  const location = useLocation()

  const noNeedLogin = [
    pathNameMap.login,
    pathNameMap.maintenance,
    pathNameMap.register,
    pathNameMap.successRegister,
    pathNameMap.completeChangePassword,
    pathNameMap.resetPassword,
    pathNameMap.resetPasswordSuccess,
    pathNameMap.tokenError,
  ]

  const token = localStorage.getItem('react_management_token')
  if (!noNeedLogin.includes(location.pathname) && !token && location.pathname !== pathNameMap.login) {
    return <ToLogin />
  }

  if (token && location.pathname === pathNameMap.login) {
    return <ToHome />
  }


  if (noNeedLogin.includes(location.pathname)) {
    return router
  }

  return <ReferenceDateProvider>
    {router}
  </ReferenceDateProvider>
}

const App = () => {
  return (
    <ConfigProvider autoInsertSpaceInButton={false} locale={jaJP} >
      <BeforeRouterEnter />
    </ConfigProvider>
  )
}

export default App
