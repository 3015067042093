import { createContext, useState } from "react";

export const FacilityNewsParams = createContext({} as {
    facilityNewsParams: GetFacilityNewsApiRequest
    setFacilityNewsParams: React.Dispatch<React.SetStateAction<GetFacilityNewsApiRequest>>
})

export const GetFacilityNewsParamsProvider = ({ children }: {
    children?: React.ReactNode;
}) => {

    const [facilityNewsParams, setFacilityNewsParams] = useState<GetFacilityNewsApiRequest>({ page: 1, pageSize: 3, facilityId: ""});
    const facilityNewsParamsValue = { facilityNewsParams, setFacilityNewsParams }

    return (
        <FacilityNewsParams.Provider value={facilityNewsParamsValue}>
            {children}
        </FacilityNewsParams.Provider>
    );
};