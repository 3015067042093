import { Outlet } from "react-router-dom";
import { Content, Footer } from "antd/es/layout/layout";
import { Layout } from "antd";

const View = () => {

    return (
        <Layout style={{ height: '100vh' }}>
            <Content>
                <Outlet />
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: "#001529", color: "white", padding: "15px 50px" }}>Copyright ©オアシスルーム予約システム All Rights Reserved.</Footer>
        </Layout >
    );
}

export default View

