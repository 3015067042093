import { GetOtherInformationApi } from '@/api/otherInformation'
import useSWR from 'swr'

export const useOtherInformation = () => {
  const options = {
    // ウィンドウがフォーカスされたときに自動的に再検証する
    revalidateOnFocus: false,
    // ブラウザが接続を回復すると自動的に再検証する
    revalidateOnReconnect: false,
    // fetcherでエラーが起きた時に再検証する
    shouldRetryOnError: false,
  }

  const getOtherInformation = (infoCode: string) => {
    const {
      data: res,
      error,
      isLoading,
      isValidating,
    } = useSWR(infoCode ? infoCode : null, GetOtherInformationApi, options)
    const data = res?.data
    return {
      otherInformationData: data,
      isLoading,
      isValidating,
    }
  }

  return { getOtherInformation }
}
