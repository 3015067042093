import service from ".";

/**
 * ## その他情報取得API
 * 
 * 指定したコードの情報を取得します
 * 
 * | STATUS | Result |
 * | ------- | ------- |
 * | 200  | 成功 |
 * | 404  | リソースが見つかりません  |
 */

export const GetOtherInformationApi = (infoCode: string): Promise<BaseResponse<GetOtherInformationApiResult>> =>
    service.get('/info/' + infoCode);
