import { createContext, useState } from "react";

type UnavailableTime = {
    startUnavailableTime: number[],
    startUnavailableMinutes: Map<number, number[]>,
    endUnavailableTime: Map<string, number[]>,
    endUnavailableMinutes: Map<number[],Map<number, number[]>>
}

export const UnavailableTimeContext = createContext({} as {
    unavailableTime: UnavailableTime | undefined
    setUnavailableTime: React.Dispatch<React.SetStateAction<UnavailableTime | undefined>>
})

export const UnavailableTimeProvider = ({ children }: {
    children?: React.ReactNode;
}) => {

    const [unavailableTime, setUnavailableTime] = useState<UnavailableTime>()
    const unavailableTimeValue = { unavailableTime, setUnavailableTime }

    return (
        <UnavailableTimeContext.Provider value={unavailableTimeValue}>
            {children}
        </UnavailableTimeContext.Provider>
    );
};