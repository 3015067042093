import { createContext, useState } from "react";

export const Collapsed = createContext({} as {
    collapsed: boolean
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
})

export const CollapsedProvider = ({ children }: {
    children?: React.ReactNode;
}) => {

    const [collapsed, setCollapsed] = useState(false)
    const collapsedValue = { collapsed, setCollapsed }

    return (
        <Collapsed.Provider value={collapsedValue}>
            {children}
        </Collapsed.Provider>
    );
};
