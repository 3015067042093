import { createContext, useState } from "react";

export const ReadContext = createContext({} as {
    readOnly: boolean
    setReadOnly: React.Dispatch<React.SetStateAction<boolean>>
})

export const ReadOnlyProvider = ({ children }: {
    children?: React.ReactNode;
}) => {

    const [readOnly, setReadOnly] = useState(true)
    const readValue = { readOnly, setReadOnly }
    // console.log("aaa")

    return (
        <ReadContext.Provider value={readValue}>
            {children}
        </ReadContext.Provider>
    );
};