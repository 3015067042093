import { useEffect, useState, createContext, useContext } from 'react'

export const BaseContext = createContext(
  {} as {
    reservationItem: Object
    setReservationItem: React.Dispatch<React.SetStateAction<Object>>
  }
)

export const BaseProvider = ({ children }: { children?: React.ReactNode }) => {
  const [reservationItem, setReservationItem] = useState<Object>({})

  const value = {
    reservationItem,
    setReservationItem,
  }
  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>
}

export function useBase() {
 return useContext(BaseContext)
}
